import * as React from "react";
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Button,
  CardActions,
  Checkbox,
  CircularProgress,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableRow,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Link, useNavigate, useParams } from "react-router-dom";
import useTeam, {
  getTeamBoost,
  postTeamImage,
  putArchiveTeam,
  putTeam,
  putUnArchiveTeam,
} from "hooks/useTeam";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import EditIcon from "@mui/icons-material/Edit";
import TableContainer from "@mui/material/TableContainer";
import TableCell from "@mui/material/TableCell";
import { TeamUsers } from "components/TeamUsers";
import { TeamIcon } from "../components/brandlibrary/MingleIcons";
import ButtonOrganization from "../components/ButtonOrganization";
import ButtonExportData from "../components/ButtonExportData";
import ButtonLeague from "../components/ButtonLeague";
import ArchiveIcon from "@mui/icons-material/Archive";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import useOpponents from "../hooks/useOpponents";
import {
  IBoostStatus,
  ITeamEdit,
  ITeamMember,
  IUser,
  IUsers,
  ManagementRolesEnum,
  RoleEnum,
} from "../utils/api.interfaces";
import { removeDuplicate } from "../utils/common";
import { nanoid } from "nanoid";
import TransferCards from "../components/TransferCards";
import ListItemText from "@mui/material/ListItemText";
import Radio from "@mui/material/Radio";
import { useFormik } from "formik";
import ButtonArchiveTeam from "../components/ArchiveTeamButton";
import { fetchUser } from "../hooks/useUser";
import ButtonBoosterUser from "components/ButtonBoosterUser";
import ButtonBoostTeam from "../components/ButtonBoostTeam";
import ButtonBoosterContainer from "../components/ButtonBoosterContainer";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export default function Team() {
  const { teamId } = useParams();
  const { dataOpponents } = useOpponents(teamId);
  const theme = useTheme();
  const navigate = useNavigate();
  const { dataTeamIsLoading, dataTeam, refetch } = useTeam(teamId);
  const [boostStatus, setBoostStatus] = React.useState<IBoostStatus | null>(
    null
  );
  const [boostUsersIds, setBoostUsersIds] = React.useState<string[] | null>(
    null
  );
  const teamUrl =
    process.env.REACT_APP_ENV === "PROD"
      ? "https://app.mingle.sport/public/team"
      : "https://develop.app-mingle-sport.pages.dev/public/team";
  React.useEffect(() => {
    if (teamId) {
      getTeamBoost(teamId).then((res) => setBoostStatus(res.boostStatus));
    }
  }, [teamId, dataTeam]);
  React.useEffect(() => {
    const boostUsersId = [
      ...new Set(
        boostStatus?.teamBoosters.map((booster) => {
          return booster.userId;
        })
      ),
    ];
    setBoostUsersIds(boostUsersId);
  }, [boostStatus]);
  return (
    <>
      <Typography
        component="h1"
        variant="h1"
        color="inherit"
        noWrap
        gutterBottom
        sx={{
          textAlign: "center",
          margin: "40px 0px",
        }}
      >
        {`Team: ${dataTeam?.organization?.name} - ${dataTeam?.name}`}
      </Typography>
      <Stack
        spacing={3}
        sx={{
          flexDirection: "column",

          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <Card sx={{ width: "100%", maxWidth: "1150px", margin: "auto" }}>
          <CardContent>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="space-between"
              spacing={2}
              sx={{ width: "100%" }}
            >
              <Button
                component={Link}
                size="small"
                variant="contained"
                color="success"
                to={`/teams/add/`}
                startIcon={<TeamIcon />}
              >
                add new team
              </Button>
              <Button
                component={Link}
                size="small"
                variant="outlined"
                color="secondary"
                to={`/teams/edit/${
                  dataTeam?.id ? dataTeam?.id : dataTeam?.teamId
                }`}
                startIcon={<EditIcon />}
              >
                edit
              </Button>
            </Stack>
            <Avatar sx={{ width: 175, height: 175, margin: "auto" }}>
              <img
                src={dataTeam?.logoUrl}
                style={{ width: "140px", height: "auto" }}
              />
            </Avatar>

            <TableContainer>
              <Table
                aria-label="user details table"
                sx={{
                  marginBottom: theme.spacing(6),
                }}
              >
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row" colSpan={2}>
                      <Typography
                        variant="h1"
                        color="text.secondary"
                        align="center"
                        gutterBottom
                      >
                        {dataTeam?.name}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      nameAbbreviation:
                    </TableCell>
                    <TableCell align="right">
                      {dataTeam?.nameAbbreviation}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      inviteCode: :
                    </TableCell>
                    <TableCell align="right">{dataTeam?.inviteCode}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      SportType:
                    </TableCell>
                    <TableCell align="right">{dataTeam?.sportType}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      teamId:
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        component="a"
                        target="_blank"
                        size="small"
                        variant="text"
                        color="info"
                        href={`${teamUrl}/${dataTeam?.id}`}
                        sx={{ whiteSpace: "nowrap" }}
                        startIcon={<>⚽</>}
                        endIcon={<ChevronRightIcon />}
                      >
                        Public team page
                      </Button>

                      {dataTeam?.id}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      League:
                    </TableCell>
                    <TableCell align="right">
                      <ButtonLeague leagueId={dataTeam?.league?.id} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      organization:
                    </TableCell>
                    <TableCell align="right">
                      <ButtonOrganization
                        organizationId={dataTeam?.organization?.id}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Opponents:
                    </TableCell>
                    <TableCell align="right">
                      <Stack direction="row" flexWrap="wrap" gap={2}>
                        {dataOpponents?.opponents.map((opponent) => {
                          return (
                            <Button
                              key={nanoid(6)}
                              color={"secondary"}
                              sx={{
                                cursor: "pointer",
                                fontSize: "12px",
                                height: "42px",
                                pointerEvents: "none",
                                whiteSpace: "nowrap",
                              }}
                              size="small"
                              startIcon={
                                <Avatar
                                  sx={{
                                    backgroundColor: "#fff",
                                    marginRight: "6px",
                                    width: "35px",
                                    height: "35px",
                                  }}
                                  src={opponent.logoUrl}
                                ></Avatar>
                              }
                              variant="outlined"
                            >
                              {opponent.name}
                            </Button>
                          );
                        })}
                      </Stack>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Boost Team:
                    </TableCell>
                    <TableCell align="right">
                      <Stack
                        spacing={6}
                        direction="row"
                        justifyContent="end"
                        alignItems="center"
                        alignContent="center"
                      >
                        {dataTeam?.id && (
                          <ButtonBoostTeam
                            teamId={dataTeam.id}
                            refetch={() => {
                              if (teamId)
                                getTeamBoost(teamId).then((res) =>
                                  setBoostStatus(res.boostStatus)
                                );
                              return refetch();
                            }}
                          />
                        )}
                      </Stack>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      TeamBoostLevel:
                    </TableCell>
                    <TableCell align="right">
                      <Stack
                        spacing={6}
                        direction="row"
                        justifyContent="end"
                        alignItems="center"
                        alignContent="center"
                      >
                        <Typography variant="body2">
                          {boostStatus?.boostLevel}
                        </Typography>
                      </Stack>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      TeamBoosters:
                    </TableCell>
                    <TableCell align="right">
                      <Stack
                        spacing={6}
                        direction="row"
                        justifyContent="end"
                        alignItems="center"
                        alignContent="center"
                      >
                        {dataTeam?.id && (
                          <>
                            {boostUsersIds?.map((userId) => {
                              return (
                                <div key={nanoid(6)}>
                                  <ButtonBoosterContainer
                                    userId={userId}
                                    teamId={dataTeam.id}
                                    refetch={refetch}
                                  />
                                </div>
                              );
                            })}
                          </>
                        )}
                      </Stack>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      isArchived:
                    </TableCell>
                    <TableCell align="right">
                      <Stack
                        spacing={6}
                        direction="row"
                        justifyContent="end"
                        alignItems="center"
                        alignContent="center"
                      >
                        <Typography variant="body2">
                          {dataTeam?.isArchived?.toString()}
                        </Typography>
                        <ButtonArchiveTeam team={dataTeam} refetch={refetch} />
                      </Stack>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      isGhost:
                    </TableCell>
                    <TableCell align="right">
                      {dataTeam?.isGhost?.toString()}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      isHistoric:
                    </TableCell>
                    <TableCell align="right">
                      {dataTeam?.isHistoric?.toString()}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      Seasons:
                    </TableCell>
                    <TableCell align="right">
                      {dataTeam?.season && (
                        <div>
                          <strong>{dataTeam?.season.name}</strong>
                          <br />
                          {new Date(
                            dataTeam?.season.startsAtUtc
                          ).toLocaleDateString([], {
                            weekday: "short",
                            year: "numeric",
                            month: "numeric",
                            day: "numeric",
                          })}
                          -
                          {new Date(
                            dataTeam?.season.endsAtUtc
                          ).toLocaleDateString([], {
                            weekday: "short",
                            year: "numeric",
                            month: "numeric",
                            day: "numeric",
                          })}
                          <hr />
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            {dataTeam && (
              <>
                <Card>
                  <CardContent>
                    <Stack
                      spacing={2}
                      direction="column"
                      justifyContent="space-between"
                      alignItems="center"
                      alignContent="center"
                    >
                      <table cellPadding={10} cellSpacing={2}>
                        <thead>
                          <tr>
                            <th colSpan={8}>
                              {" "}
                              <Typography
                                variant="h1"
                                sx={{
                                  fontWeight: "700",
                                  marginBottom: "16px",
                                  fontSize: "28px",
                                  textAlign: "center",
                                }}
                              >
                                Members & Roles & Permissions
                              </Typography>
                            </th>
                          </tr>
                          <tr>
                            <th align={"left"}></th>
                            <th>Player</th>
                            <th>Trainer</th>
                            <th style={{ paddingRight: "60px" }}>Support</th>
                            <th>Admin</th>
                            <th>Content Creator</th>
                            <th>Scheduler</th>
                            <th>Scorekeeper</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dataTeam.members?.map((member) => {
                            return (
                              <tr key={nanoid(6)}>
                                <td align={"left"}>
                                  <Stack
                                    spacing={0}
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="space-between"
                                    alignContent="space-between"
                                    key={member.id}
                                    onClick={() =>
                                      navigate(
                                        !member.id.includes("pre", 0)
                                          ? `/users/${member.id} `
                                          : `/users/pre/${member.id} `
                                      )
                                    }
                                  >
                                    <Stack
                                      spacing={2}
                                      direction="row"
                                      justifyContent="center"
                                      alignItems="center"
                                      alignContent="center"
                                      sx={{ width: "320px", cursor: "pointer" }}
                                    >
                                      <Avatar
                                        alt={`${member.firstName} ${member.lastName}`}
                                        src={
                                          member.imageUrl
                                            ? member.imageUrl
                                            : member.profileImageUrl
                                        }
                                        sx={{ width: 24, height: 24 }}
                                      />
                                      {member.isPreUser && <>🥚</>}
                                      <ListItemText
                                        primary={`${member.firstName} ${member.lastName}`}
                                        secondary={`${member.email}`}
                                      />
                                    </Stack>
                                  </Stack>
                                </td>
                                <td align={"center"}>
                                  <Radio
                                    name={`role-${member.id}`}
                                    value={RoleEnum.Player}
                                    checked={member.role === RoleEnum.Player}
                                    disabled={true}
                                  />
                                </td>
                                <td>
                                  <Radio
                                    name={`role-${member.id}`}
                                    checked={member.role === RoleEnum.Trainer}
                                    value={RoleEnum.Trainer}
                                    disabled={true}
                                  />
                                </td>
                                <td
                                  align={"center"}
                                  style={{ paddingRight: "60px" }}
                                >
                                  <Radio
                                    name={`role-${member.id}`}
                                    value={RoleEnum.Support}
                                    checked={member.role === RoleEnum.Support}
                                    disabled={true}
                                  />
                                </td>
                                <td align={"center"}>
                                  <Checkbox
                                    title={"Admin"}
                                    sx={{}}
                                    checked={member.teamManagementRoles?.includes(
                                      ManagementRolesEnum.admin
                                    )}
                                    disabled={true}
                                  />
                                </td>
                                <td align={"center"}>
                                  <Checkbox
                                    title={"ContentCreator"}
                                    sx={{}}
                                    checked={member.teamManagementRoles?.includes(
                                      ManagementRolesEnum.contentCreator
                                    )}
                                    disabled={true}
                                  />
                                </td>
                                <td align={"center"}>
                                  <Checkbox
                                    title={"Scheduler"}
                                    sx={{}}
                                    checked={member.teamManagementRoles?.includes(
                                      ManagementRolesEnum.scheduler
                                    )}
                                    disabled={true}
                                  />
                                </td>
                                <td align={"center"}>
                                  <Checkbox
                                    title={"Scorekeeper"}
                                    checked={member.teamManagementRoles?.includes(
                                      ManagementRolesEnum.scorekeeper
                                    )}
                                    disabled={true}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </Stack>
                  </CardContent>
                </Card>
              </>
            )}
          </CardContent>

          <CardActions>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="space-between"
              spacing={2}
              sx={{ width: "100%", padding: 2 }}
            >
              <Button
                component={Link}
                size="small"
                variant="contained"
                color="success"
                to={`/teams/add/`}
                startIcon={<TeamIcon />}
              >
                add new team
              </Button>
              <Button
                component={Link}
                size="small"
                variant="outlined"
                color="secondary"
                to={`/teams/edit/${dataTeam?.id}`}
                startIcon={<EditIcon />}
              >
                edit
              </Button>
            </Stack>
          </CardActions>
        </Card>
        <Button
          size="small"
          variant="outlined"
          onClick={() => navigate(-1)}
          startIcon={<ChevronLeftIcon />}
        >
          back
        </Button>
      </Stack>
    </>
  );
}
